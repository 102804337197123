import { Box, Container, Stack } from "@mui/material";
import { DividerBox } from "components/common/CustomDivider";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";

export default function WhyChoose() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 150px)"}
      display={"flex"}
      py={isMobile ? 2 : 10}
      justifyContent={"center"}
      alignItems={"center"}>
      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
        }}>
        <Stack
          gap={isMobile ? 2 : 3}
          alignItems={"center"}
          px={isDesktop ? 10 : 2}
          mb={2}>
          <TypographyWhite
            variant={isMobile ? "h4" : "h2"}
            data-aos='fade'
            data-aos-easing='linear'
            data-aos-once='true'>
            {ContentHome.WhyChoose.title}
          </TypographyWhite>
          <DividerBox height={isMobile ? 2 : 4} />
          <TypographyText
            lineHeight={isMobile ? "1.5" : "2"}
            textAlign={"center"}
            data-aos='fade-up'
            data-aos-easing='linear'
            data-aos-once='true'
            data-aos-delay='200'>
            {ContentHome.WhyChoose.description}
          </TypographyText>
        </Stack>
        <Stack
          alignItems={"center"}
          data-aos='zoom-in-up'
          data-aos-easing='linear'
          data-aos-once='true'
          data-aos-delay='300'>
          <img src={`/images/home/partner.png`} alt='' />
        </Stack>
      </Container>
    </Box>
  );
}
