import { Box, Container, Stack } from "@mui/material";
import { BoxAccent, ImgAccent } from "components/common/CustomBox";
import { TypographyWhite } from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentMM } from "./Content";

export default function Packages({ tabIndex }: { tabIndex: string }) {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={isDesktop ? "550px" : "300px"}
      py={isMobile ? 2 : 10}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}>
      <ImgAccent
        src='/images/mm/frame-mmbot-title.png'
        alt=''
        sx={{
          width: "100%",
          zIndex: 0,
          top: "0",
          left: "0",
        }}
      />
      <ImgAccent
        src='/images/home/star-1.png'
        alt=''
        sx={{
          width: isMobile ? "25px" : "35px",
          zIndex: 0,
          bottom: isDesktop ? "18%" : "10%",
          left: "25%",
        }}
      />
      <ImgAccent
        src='/images/home/star-1.png'
        alt=''
        sx={{
          width: isMobile ? "25px" : "35px",
          zIndex: 0,
          bottom: "18%",
          right: "25%",
        }}
      />
      <ImgAccent
        src='/images/home/star-1.png'
        alt=''
        sx={{
          width: isMobile ? "18px" : "22px",
          zIndex: 0,
          bottom: "2%",
          left: "53%",
        }}
      />
      <BoxAccent
        sx={{
          width: "300px",
          height: "300px",
          borderRadius: "50%",
          background:
            "linear-gradient(234deg, #FA11FF 25.01%, rgba(66, 66, 66, 0.00) 93.67%, rgba(46, 46, 46, 0.00) 93.67%)",
          filter: "blur(225px)",
          zIndex: 0,
          bottom: "1%",
          right: "5%",
        }}
      />
      <BoxAccent
        sx={{
          width: "300px",
          height: "300px",
          borderRadius: "50%",
          background:
            "linear-gradient(234deg, #FA11FF 25.01%, rgba(66, 66, 66, 0.00) 93.67%, rgba(46, 46, 46, 0.00) 93.67%)",
          filter: "blur(225px)",
          zIndex: 0,
          bottom: "1%",
          right: "5%",
        }}
      />

      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
          mt: 5,
        }}>
        <Stack
          alignItems={"center"}
          data-aos='zoom-in-up'
          data-aos-easing='linear'
          data-aos-once='true'>
          <TypographyWhite
            variant={isMobile ? "h3" : "h1"}
            className='special-font'
            sx={{
              fontFamily: "'Syne', sans-serif!important",
              textAlign: isDesktop ? "left" : "center",
            }}>
            {tabIndex === "0"
              ? ContentMM.Packages.BotRental
              : ContentMM.Packages.MM}
          </TypographyWhite>
        </Stack>
      </Container>
    </Box>
  );
}
