import { Box, Container, Stack } from "@mui/material";
import { ImgAccent } from "components/common/CustomBox";
import { DividerBox } from "components/common/CustomDivider";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";

export default function LatestUpdate() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={isDesktop ? "130vh" : "100vh"}
      pt={isMobile ? 2 : 10}
      overflow={"hidden"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      sx={{
        background:
          "linear-gradient(180deg, rgba(14, 10, 24, 0.00) 0%, #0E0A18 58.74%)",
      }}>
      <ImgAccent
        src='/images/home/stars.png'
        className='rotate-stars'
        sx={{
          width: isMobile ? "150%" : "100%",
          maxWidth: isMobile ? "150%" : "100%",
          top: isMobile ? "10%" : "-40%",
          zIndex: 0,
        }}
      />
      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
          mb: isMobile ? -30 : 5,
        }}>
        <Stack
          gap={isMobile ? 2 : 3}
          alignItems={"center"}
          mb={isMobile ? 2 : 4}>
          <TypographyWhite
            variant={isMobile ? "h4" : "h2"}
            data-aos='fade'
            data-aos-easing='linear'
            data-aos-once='true'>
            {ContentHome.Updates.title}
          </TypographyWhite>
          <DividerBox height={isMobile ? 2 : 4} />
          <TypographyText
            data-aos='fade-up'
            data-aos-easing='linear'
            data-aos-delay='200'
            data-aos-once='true'
            lineHeight={isMobile ? "1.5" : "2"}
            textAlign={"center"}
            sx={{ maxWidth: isMobile ? "100%" : "65%" }}>
            {ContentHome.Updates.description}
          </TypographyText>
        </Stack>
      </Container>
      <Box mb={isMobile ? -45 : "min(-17%,-210px)"}>
        <Box
          position={"relative"}
          sx={{ transform: isMobile ? "scale(0.5)" : "scale(1)" }}>
          <Box className='orbit-6' sx={{ padding: 8 }}>
            <Box className='orbit-5' sx={{ padding: 8 }}>
              <Box className='orbit-4' sx={{ padding: 8 }}>
                <Box className='orbit-3' sx={{ padding: 8 }}>
                  <Box className='orbit-2' sx={{ padding: 8 }}>
                    <Box
                      className='orbit-1'
                      sx={{ width: "400px", height: "400px" }}>
                      <Box className='orbit-round' />
                    </Box>
                    <Box className='orbit-round' />
                  </Box>
                  <Box className='orbit-round' />
                </Box>
                <Box className='orbit-round' />
              </Box>
              <Box className='orbit-round' />
            </Box>
            <Box className='orbit-round' />
          </Box>
          <ImgAccent
            alt=''
            src='/images/home/robot-2.png'
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "350px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
