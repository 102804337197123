import {
  About,
  GetInTouch,
  Introduction,
  LatestUpdate,
  Networks,
  Pricing,
  Services,
  Solutions,
  Supported,
  WhyChoose,
} from "./components";

export default function HomePage() {
  return (
    <>
      <Introduction />
      <Networks />
      <About />
      <Services />
      <Solutions />
      <Supported />
      <WhyChoose />
      <Pricing />
      <GetInTouch />
      <LatestUpdate />
    </>
  );
}
