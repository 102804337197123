import { TabContext, TabPanel } from "@mui/lab";
import { Box, Container, Divider, Stack, Tab } from "@mui/material";
import { BoxAccent } from "components/common/CustomBox";
import { TypographyWhite } from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ContentMM } from "./Content";
import { BoxPackagesPrice, SpecialTabList } from "./Styled";

export default function PackagesPrice({
  onChanged,
}: {
  onChanged: (value: string) => void;
}) {
  const isMobile = useResponsive("down", "sm");
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState("0");
  useEffect(() => {
    if (pathname.includes("bot-rental")) {
      setTabIndex("0");
      onChanged("0");
    } else {
      setTabIndex("1");
      onChanged("1");
    }
  }, [pathname, onChanged]);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabIndex(newValue.toString());
    onChanged(newValue.toString());
    switch (newValue) {
      case "0":
        navigate("/pricing-bot-rental-service");
        break;
      case "1":
        navigate("/pricing-market-making-service");
        break;
      default:
        navigate("/pricing-bot-rental-service");
    }
  };

  return (
    <Box
      position={"relative"}
      minHeight={isMobile ? "auto" : "calc(100vh - 150px)"}
      py={isMobile ? 2 : 10}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      data-aos='fade-up'
      data-aos-easing='linear'
      data-aos-once='true'
      data-aos-delay='200'>
      <BoxAccent
        sx={{
          width: "550px",
          height: "550px",
          borderRadius: "50%",
          background: "rgba(120, 74, 253, 0.30)",
          filter: "blur(250px)",
          zIndex: 0,
          bottom: "-15%",
          right: "-10%",
        }}
      />
      <BoxAccent
        sx={{
          width: "450px",
          height: "450px",
          borderRadius: "50%",
          background: "#2745EA",
          filter: "blur(250px)",
          zIndex: 0,
          bottom: "-25%",
          left: "-15%",
        }}
      />
      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
        }}>
        <Box position={"relative"}>
          <BoxAccent
            sx={{
              width: "120%",
              height: "50px",
              background: "rgb(111,60,246,0.75)",
              filter: "blur(150px)",
              zIndex: 0,
              left: "-10%",
              bottom: "0px",
            }}
          />
          <BoxAccent
            sx={{
              width: "100%",
              height: "100px",
              background: "var(--linear-primary)",
              zIndex: 0,
              left: "0%",
              bottom: "-4px",
              borderRadius: isMobile ? "0 0 16px 16px" : "0 0 30px 30px",
            }}
          />
          <BoxPackagesPrice py={isMobile ? 2 : 10} px={isMobile ? 2 : 4}>
            <Box color={"#fff"}>
              <TabContext value={tabIndex}>
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  alignItems={"center"}>
                  <Stack alignItems={isMobile ? "center" : "flex-end"}>
                    <SpecialTabList
                      indicatorColor={"secondary"}
                      onChange={handleChange}>
                      <Tab label='Bot-Rental services' value='0' />
                      <Tab label='Market Making Services' value='1' />
                    </SpecialTabList>
                  </Stack>
                </Stack>
                <Divider sx={{ mt: 3, color: "#182542" }} />
                <Stack alignItems={"center"} my={4}>
                  <TypographyWhite
                    textAlign={"center"}
                    variant={isMobile ? "caption" : "body1"}>
                    {ContentMM.Packages.subTitle1}
                  </TypographyWhite>
                  <TypographyWhite
                    textAlign={"center"}
                    variant={isMobile ? "caption" : "body1"}>
                    {ContentMM.Packages.subTitle2}
                  </TypographyWhite>
                </Stack>
                <TabPanel
                  value={tabIndex}
                  sx={{ padding: { md: "32px 0 0", xs: "0" } }}>
                  {tabIndex === "0" && (
                    <Stack
                      alignItems={"center"}
                      data-aos='zoom-in'
                      data-aos-duration='300'
                      data-aos-easing='linear'
                      data-aos-once='true'
                      data-aos-delay='200'>
                      <img
                        src={ContentMM.Packages.BotRentalImg}
                        alt=''
                        style={{ backdropFilter: "blur(10px)" }}
                      />
                    </Stack>
                  )}
                  {tabIndex === "1" && (
                    <Stack
                      alignItems={"center"}
                      data-aos='zoom-in'
                      data-aos-duration='300'
                      data-aos-easing='linear'
                      data-aos-once='true'
                      data-aos-delay='200'>
                      <img
                        src={ContentMM.Packages.MMImg}
                        alt=''
                        style={{ backdropFilter: "blur(10px)" }}
                      />
                    </Stack>
                  )}
                </TabPanel>
              </TabContext>
            </Box>
          </BoxPackagesPrice>
        </Box>
      </Container>
    </Box>
  );
}
