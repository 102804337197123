import { Box, Container, Grid, Stack } from "@mui/material";
import { BoxRadialBorderPrimary, ImgAccent } from "components/common/CustomBox";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";

export default function Solutions() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 150px)"}
      py={isMobile ? 2 : 10}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      data-aos='fade-up'
      data-aos-easing='linear'
      data-aos-once='true'>
      <BoxRadialBorderPrimary
        width={"100%"}
        sx={{ padding: isMobile ? 1 : "auto" }}>
        <Container
          maxWidth='xl'
          sx={{
            zIndex: 1,
            position: "inherit",
          }}>
          <Grid
            container
            my={isMobile ? 4 : 8}
            sx={{
              justifyContent: "space-between",
              flexDirection: isDesktop ? "row" : "column-reverse",
            }}>
            <Grid item xs={12} md={5.25}>
              <Stack
                alignItems={isDesktop ? "start" : "center"}
                data-aos='flip-left'
                data-aos-easing='linear'
                data-aos-once='true'
                data-aos-delay='400'>
                <Box
                  component={"img"}
                  src='/images/home/solutions.png'
                  alt=''
                  mt={isDesktop ? 0 : 4}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={5.25}
              data-aos='fade-up'
              data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='200'>
              <Box position={"relative"} mb={isMobile ? 2 : 4}>
                <TypographyWhite variant={isMobile ? "h4" : "h2"}>
                  {ContentHome.Solutions.title}
                </TypographyWhite>
                <ImgAccent
                  src='/images/home/star-3.png'
                  width={isMobile ? 30 : "auto"}
                  alt=''
                  sx={{ bottom: "8px", left: "85%" }}
                />
              </Box>
              <Box>
                <TypographyText
                  lineHeight={isMobile ? "1.5" : "2"}
                  textAlign={"justify"}>
                  {ContentHome.Solutions.description}
                </TypographyText>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </BoxRadialBorderPrimary>
    </Box>
  );
}
