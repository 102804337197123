import { Box } from "@mui/material";

export const DividerBox = ({
  width = 160,
  height = 4,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <Box
      sx={{
        borderRadius: "0",
        width: width,
        height: height,
        background: "var(--linear-primary)",
      }}
      data-aos='flip-left'
      data-aos-easing='linear'
      data-aos-once='true'
    />
  );
};
