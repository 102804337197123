// export const buyOnSliderSettings = {
//     dots: false,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     infinite: true,
//     speed: 700,
//     vertical: true,
//     verticalSwiping: true,
//     adaptiveHeight: true,
//     variableHeight: false,
//     autoplaySpeed: 3000,
//     autoplay: true,
//     arrows: false,
// };

export const networksSliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplaySpeed: 3000,
  autoplay: true,
  arrows: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};
