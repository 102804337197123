import { Box, Container, Grid, Link, Stack } from "@mui/material";
import { BoxAccent } from "components/common/CustomBox";
import { ButtonLoadingPrimary } from "components/common/CustomButton";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";
import { AppConfig } from "settings";

export default function Introduction() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={isMobile ? "auto" : "100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      pb={10}
      pt={!isDesktop ? 12 : 0}
      sx={{
        background: 'url("/images/home/frame-title.png")',
        backgroundSize: isDesktop ? "100%" : "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
      }}>
      <BoxAccent
        sx={{
          width: "500px",
          height: "500px",
          borderRadius: "50%",
          background: "#2745EA",
          filter: "blur(250px)",
          zIndex: 0,
          top: "-20%",
          left: "-10%",
        }}
      />
      <BoxAccent
        sx={{
          width: "550px",
          height: "550px",
          borderRadius: "50%",
          background: "#925792",
          filter: "blur(350px)",
          zIndex: 0,
          top: "20%",
          right: "-10%",
        }}
      />
      <BoxAccent
        sx={{
          width: "300px",
          height: "300px",
          borderRadius: "50%",
          background:
            "linear-gradient(234deg, #FA11FF 25.01%, rgba(66, 66, 66, 0.00) 93.67%, rgba(46, 46, 46, 0.00) 93.67%)",
          filter: "blur(225px)",
          zIndex: 0,
          bottom: "25%",
          right: "15%",
        }}
      />
      <Container maxWidth='xl' sx={{ zIndex: 1, position: "inherit" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Stack
              gap={4}
              alignItems={isMobile ? "center" : "flex-start"}
              justifyContent={"center"}
              data-aos='zoom-in'
              data-aos-once='true'>
              <TypographyWhite
                variant={isMobile ? "h3" : "h1"}
                fontWeight={600}
                textAlign={isMobile ? "center" : "left"}>
                {ContentHome.Introduce.title}
              </TypographyWhite>
              <TypographyText
                data-aos='fade-up'
                data-aos-once='true'
                data-aos-delay='200'
                lineHeight={isMobile ? "1.5" : "2"}
                mr={4}
                textAlign={isMobile ? "center" : "left"}>
                {ContentHome.Introduce.description}
              </TypographyText>
              <Link href={`${AppConfig.APP_URL}`}>
                <ButtonLoadingPrimary
                  className='shine'
                  data-aos='fade-up'
                  data-aos-once='true'
                  data-aos-delay='400'>
                  Explore Our Services
                </ButtonLoadingPrimary>
              </Link>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack
              gap={4}
              alignItems={isMobile ? "center" : "flex-end"}
              mt={isMobile ? 0 : -10}
              data-aos='zoom-in-left'
              data-aos-delay='600'>
              <Box
                component={"img"}
                src='/images/home/title.png'
                alt=''
                sx={{ width: isDesktop ? "auto" : "60%" }}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
