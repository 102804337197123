import { Box, styled } from "@mui/material";

export const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#050505",
  backgroundImage: "url(/images/home/bg-footer.png)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center",
  paddingTop: 56,
  position: "relative",
  zIndex: 2,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 32,
  },
}));
export const EndBox = styled(Box)(({ theme }) => ({
  background:
    "linear-gradient(150deg, rgba(39, 69, 234, 0.20) , rgba(207, 123, 244, 0.20))",
  textAlign: "center",
  padding: 8,
  marginTop: 80,
  "& p": {
    fontSize: 12,
    fontWeight: 500,
    color: "white",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: 40,
  },
}));
