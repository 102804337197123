import {
  Box,
  Container,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { TypographyText } from "components/common/CustomTypography";
import Logo from "components/common/Logo";
import useResponsive from "hooks/useResponsive";
import React from "react";
import { NavLink } from "react-router-dom";
import { EndBox, FooterBox } from "./components/FooterStyled";
import { MENU_FOOTER_CONFIG } from "./components/Menu";
const content =
  "SPTrade Pro is a proprietary trading firm specializing in providing liquidity in various crypto assets. We do not engage in the management of crypto assets or fiat currency on behalf of investors, nor do we hold fiat currency or crypto assets on behalf of investors or customers. SPTrade Pro complies with all applicable regulations and is dedicated to maintaining the highest standards of integrity and professionalism in our relationships with clients. © 2023 SPTrade Pro. All Rights Reserved.";
export default function Footer() {
  const isMobile = useResponsive("down", "sm");
  const scroll = (id: string) => {
    const section = document.querySelector(`${id}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  return (
    <FooterBox>
      <Container maxWidth={"xl"}>
        <Grid container>
          <Grid xs={12} md={6} item>
            <Box>
              <Logo sx={{ width: isMobile ? "50%" : "240px" }} />
              <Typography
                variant={isMobile ? "caption" : "body2"}
                color={"#7C7B97"}
                mt={isMobile ? 1 : 2}
                display={"block"}>
                {content}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} md={6} item>
            <Stack alignItems={isMobile ? "center" : "flex-end"} gap={3} mt={5}>
              <Stack
                direction={"row"}
                justifyContent={isMobile ? "center" : "flex-end"}
                gap={2}
                flexWrap={isMobile ? "wrap" : "unset"}>
                {MENU_FOOTER_CONFIG.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.url.indexOf("#") <= -1 ? (
                      <NavLink
                        to={item.url}
                        onClick={() => {
                          scroll(item?.urlId || "");
                        }}>
                        <TypographyText
                          variant={isMobile ? "caption" : "body2"}>
                          {item.title}
                        </TypographyText>
                      </NavLink>
                    ) : (
                      <NavLink
                        to={item.url}
                        onClick={() => {
                          scroll(item?.urlId || "");
                        }}>
                        <TypographyText
                          variant={isMobile ? "caption" : "body2"}>
                          {item.title}
                        </TypographyText>
                      </NavLink>
                    )}
                    {MENU_FOOTER_CONFIG.length - 1 !== index && (
                      <Divider orientation='vertical' flexItem />
                    )}
                  </React.Fragment>
                ))}
              </Stack>
              {/* <Stack direction={"row"} gap={4}>
                {MENU_SOCIAL_CONFIG.map((item, index) => (
                  <Link href={item.url} target='_blank' rel='noreferrer'>
                    <img src={item.src} alt='' />
                  </Link>
                ))}
              </Stack> */}
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <EndBox>
        <Typography variant='body2'>
          Copyright © 2023 SPTradepro | All Rights Reserved
        </Typography>
      </EndBox>
    </FooterBox>
  );
}
