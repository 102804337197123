import { AppConfig } from "settings";

export const MENU_CONFIG = [
  {
    title: "Home",
    key: "",
    externalLink: false,
    url: "/",
    urlId: "#top",
  },
  {
    title: "Services",
    key: "",
    externalLink: false,
    url: "/#services",
    urlId: "#services",
  },
  {
    title: "Supported Exchanges",
    key: "",
    externalLink: false,
    url: "/#supported-exchanges",
    urlId: "#supported-exchanges",
  },
  {
    title: "Pricing",
    key: "",
    externalLink: false,
    url: "/pricing-bot-rental-service",
    urlId: "#top",
    subMenu: [
      {
        title: "Bot-Rental Services",
        key: "",
        externalLink: false,
        url: "/pricing-bot-rental-service",
      },
      {
        title: "Market Making Services",
        key: "",
        externalLink: false,
        url: "/pricing-market-making-service",
      },
    ],
  },
  {
    title: "Contact",
    key: "",
    externalLink: false,
    url: "/#contact",
    urlId: "#contact",
  },
];
export const MENU_FOOTER_CONFIG = [
  {
    title: "Home",
    key: "",
    externalLink: false,
    url: "/",
    urlId: "#top",
  },
  {
    title: "Services",
    key: "",
    externalLink: false,
    url: "/#services",
    urlId: "#services",
  },
  {
    title: "Supported Exchanges",
    key: "",
    externalLink: false,
    url: "/#supported-exchanges",
    urlId: "#supported-exchanges",
  },
  {
    title: "Pricing",
    key: "",
    externalLink: false,
    url: "/pricing-bot-rental-service",
    urlId: "#top",
    subMenu: [
      {
        title: "Bot-Rental Services",
        key: "",
        externalLink: false,
        url: "/pricing-bot-rental-service",
      },
      {
        title: "Market Making Services",
        key: "",
        externalLink: false,
        url: "/pricing-market-making-service",
      },
    ],
  },
  {
    title: "Contact",
    key: "",
    externalLink: false,
    url: "/#contact",
    urlId: "#contact",
  },
];
export const MENU_SOCIAL_CONFIG = [
  {
    src: "/images/icons/instagram.png",
    url: "/",
  },
  {
    src: "/images/icons/facebook.png",
    url: "/",
  },
  {
    src: "/images/icons/linkedin.png",
    url: "/",
  },
  {
    src: "/images/icons/twitter.png",
    url: "/",
  },
];
export const MENU_EXTERNAL_CONFIG = {
  SignIn: `${AppConfig.APP_URL}`,
};
