import { Box, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import useResponsive from "hooks/useResponsive";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import UserSidebar from "./components/UserSidebar";

const RootStyle = styled("div")({
  color: "#fff",
});
const MainStyle = styled("div")(({ theme }) => ({
  background: "var(--color-third)",
  minHeight: "100vh",
  display: "flex",
  flexDirection: "row",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(6),
  },
}));

export default function AppLayout() {
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive("up", "lg");
  return (
    <RootStyle>
      <Header hiddenLogo={isDesktop ? true : false} />
      <MainStyle>
        {!isDesktop && (
          <Box
            sx={{
              display: open ? "none" : "block",
              position: "fixed",
              top: "50%",
              left: "0",
              transform: "translateY(-50%)",
              height: 64,
              borderTop: "8px solid transparent",
              borderBottom: "8px solid transparent",
              borderLeft: "10px solid #00CFFF",
              cursor: "pointer",
              "&::before": {
                content: "'›'",
                position: "absolute",
                fontWeight: "bold",
                top: "50%",
                left: "-8px",
                transform: "translateY(-50%)",
                color: "var(--color-accent)",
              },
            }}
            onClick={() => setOpen(!open)}
          />
        )}
        <UserSidebar
          isOpenSidebar={open}
          onCloseSidebar={() => setOpen(false)}
        />
        <Container maxWidth={"lg"}>
          <Outlet />
        </Container>
      </MainStyle>
    </RootStyle>
  );
}
