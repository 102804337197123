import { Box, Container, Link, Stack, Typography } from "@mui/material";
import { IconBrandTelegram, IconBrandX } from "@tabler/icons-react";
import { BoxAccent, BoxRadialBorderPrimary } from "components/common/CustomBox";
import { DividerBox } from "components/common/CustomDivider";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome, ContentSocial } from "./Content";
import { TelegramBox, TwitterBox } from "./Styled";

export default function GetInTouch() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box id={"contact"}>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 150px)"}
        py={isMobile ? 4 : 10}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        data-aos='fade-up'
        data-aos-easing='linear'
        data-aos-once='true'>
        <BoxAccent
          sx={{
            width: "100%",
            height: "100px",
            background: "rgb(111,60,246,0.5)",
            filter: "blur(200px)",
            zIndex: 0,
            bottom: "10%",
          }}
        />
        <Container
          maxWidth='xl'
          sx={{
            zIndex: 1,
            position: "inherit",
          }}>
          <BoxRadialBorderPrimary
            sx={{
              py: isMobile ? 4 : 10,
              px: isMobile ? 2 : "auto",
            }}>
            <Stack
              gap={isMobile ? 2 : 3}
              alignItems={"center"}
              px={isDesktop ? 10 : 2}
              mb={2}>
              <TypographyWhite
                variant={isMobile ? "h4" : "h2"}
                data-aos='fade'
                data-aos-easing='linear'
                data-aos-once='true'>
                {ContentHome.GetInTouch.title}
              </TypographyWhite>
              <DividerBox height={isMobile ? 2 : 4} />
              <TypographyText
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-once='true'
                data-aos-delay='200'
                lineHeight={isMobile ? "1.5" : "2"}
                sx={{ padding: isMobile ? "0" : "0 12%" }}
                textAlign={"center"}>
                {ContentHome.GetInTouch.description}
              </TypographyText>
            </Stack>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              direction={isMobile ? "column" : "row"}
              gap={isMobile ? 2 : 6}
              minWidth={"100%"}
              data-aos='fade-up'
              data-aos-easing='linear'
              data-aos-once='true'
              data-aos-delay='400'>
              {/* <Box
              component={"form"}
              width={isDesktop ? "40%" : "70%"}
              sx={{
                "& .MuiTextField-root": {
                  margin: isMobile ? "8px 0" : "12px 0",
                },
              }}>
              <TextFieldPrimary placeholder='Name' size='small' fullWidth />
              <TextFieldPrimary placeholder='Email' size='small' fullWidth />
              <TextFieldPrimary placeholder='Message' size='small' fullWidth />
              <TextFieldPrimary
                placeholder='Telegram Handle'
                size='small'
                fullWidth
              />
            </Box> */}
              {/* <ButtonLoadingSecondary className='shine'>
              Submit
            </ButtonLoadingSecondary> */}
              <Link href={ContentSocial.Telegram.link} target='_blank'>
                <TelegramBox className='shine' mt={isMobile ? 0 : 2}>
                  <Stack
                    sx={{
                      borderRadius: "8px",
                      background: "white",
                      p: isMobile ? 1.25 : 2,
                    }}>
                    <IconBrandTelegram
                      size={isMobile ? 24 : 36}
                      color='#26A4E3'
                    />
                  </Stack>
                  <Stack>
                    <TypographyWhite variant={isMobile ? "caption" : "body1"}>
                      {ContentSocial.Telegram.title}
                    </TypographyWhite>
                    <TypographyWhite
                      fontWeight={600}
                      variant={isMobile ? "h6" : "h5"}>
                      {ContentSocial.Telegram.name}
                    </TypographyWhite>
                  </Stack>
                </TelegramBox>
              </Link>
              <Link href={ContentSocial.Twitter.link} target='_blank'>
                <TwitterBox className='shine' mt={isMobile ? 0 : 2}>
                  <Stack
                    sx={{
                      borderRadius: "8px",
                      background: "#0E1125",
                      p: isMobile ? 1.25 : 2,
                    }}>
                    <IconBrandX size={isMobile ? 24 : 36} color='#FFF' />
                  </Stack>
                  <Stack>
                    <Typography variant={isMobile ? "caption" : "body1"}>
                      {ContentSocial.Twitter.title}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      variant={isMobile ? "h6" : "h5"}>
                      {ContentSocial.Twitter.name}
                    </Typography>
                  </Stack>
                </TwitterBox>
              </Link>
            </Stack>
          </BoxRadialBorderPrimary>
        </Container>
      </Box>
    </Box>
  );
}
