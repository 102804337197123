import { useState } from "react";
import { Packages, Planet } from "./components";
import PackagesPrice from "./components/PackagesPrice";

export default function MMBot() {
  const [tabIndex, setTabIndex] = useState("0");
  return (
    <>
      <Packages tabIndex={tabIndex} />
      <PackagesPrice onChanged={(value) => setTabIndex(value)} />
      <Planet />
    </>
  );
}
