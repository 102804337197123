import { Box, Container, Stack, Typography } from "@mui/material";
import Logo from "components/common/Logo";
import useResponsive from "hooks/useResponsive";

export default function NotFound() {
  const isMobile = useResponsive("down", "sm");
  return (
    <Container maxWidth='md'>
      <Stack
        sx={{ height: "60vh" }}
        justifyContent='center'
        alignItems='center'
        position={"relative"}>
        <Box data-aos='fade-up' data-aos-once='true'>
          <Logo sx={{ width: "250px" }} />
        </Box>
        <Stack data-aos='fade-up' data-aos-delay='200' data-aos-once='true'>
          <Typography fontSize={"1.2rem"} textAlign='center'>
            THIS SITE IS
          </Typography>
          <Typography fontSize={"2rem"} textAlign='center'>
            COMING SOON
          </Typography>
          <Typography fontSize={"0.9rem"} textAlign='center'>
            We are going to launch this site very Soon. Stay tune...!
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}
