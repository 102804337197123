import { Box, Drawer, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconFence } from "@tabler/icons-react";
import Logo from "components/common/Logo";
import NavSection from "components/common/NavSection";
import Scrollbar from "components/common/Scrollbar";
import useResponsive from "hooks/useResponsive";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const MENU_USER_MARKET_CONFIG: {
  icon: any;
  title: string;
  path: string;
}[] = [
  {
    icon: <IconFence />,
    title: "menu 1",
    path: "/app/menu-1",
  },
  {
    icon: <IconFence />,
    title: "menu 1",
    path: "/app/menu-1",
  },
  {
    icon: <IconFence />,
    title: "menu 1",
    path: "/app/menu-1",
  },
  {
    icon: <IconFence />,
    title: "menu 1",
    path: "/app/menu-1",
  },
];
const DRAWER_WIDTH = 260;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    zIndex: "8",
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

export default function UserSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        overflowY: "auto",
        background: "#080E0C",
        paddingTop: "88px",
        borderRight: "1px solid rgba(0, 207, 255, 0.20)",
        "& .simplebar-placeholder": {
          height: "0!important",
        },
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        "&::-webkit-scrollbar": { display: "none" },
        "& .MuiListItemButton-root": {
          color: "var(--color-gray)",
          fontSize: "1rem",
          padding: "12px 0 12px 20px",
          height: "100%",
          img: {
            width: "24px",
            filter: "grayscale(1)",
          },
          "&.active": {
            img: {
              filter: "none",
            },
            color: "white",
            background: "transparent",
            position: "relative",
            fontWeight: "bold",
            "&::before": {
              content: "''",
              position: "absolute",
              right: "0px",
              width: 0,
              height: "100%",
              borderTop: "5px solid transparent",
              borderBottom: "5px solid transparent",
              borderRight: "5px solid #00CFFF",
            },
            "&::after": {
              content: "''",
              position: "absolute",
              right: "0",
              width: "120%",
              height: "100%",
              background:
                "linear-gradient(270deg, rgba(0, 207, 255, 0.20) 0%, rgba(0, 207, 255, 0.00) 100%)",
              borderTop: "1px solid rgba(0, 207, 255, 0.20)",
              borderBottom: "1px solid rgba(0, 207, 255, 0.20)",
            },
          },
        },
      }}>
      {!isDesktop && (
        <Box
          sx={{
            position: "absolute",
            top: "0",
            width: "calc(100% - 1px)",
            zIndex: "10",
            background: "#080E0C",
            padding: "8px 20px",
          }}>
          <Logo
            sx={{
              width: "150px",
            }}
          />
        </Box>
      )}
      <Link to='/'>
        <Typography
          sx={{
            color: "#fff",
            fontWeight: "bold",
            margin: "24px 0 12px 36px",
            letterSpacing: "3px",
          }}>
          MAIN MENU
        </Typography>
      </Link>
      <NavSection navConfig={MENU_USER_MARKET_CONFIG} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}>
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              borderRight: "none",
            },
          }}>
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
