export const ContentMM = {
  Packages: {
    BotRental: "Bot-Rental Services Pricing",
    MM: "Market Making Services Pricing",
    subTitle1: "The price in the table is based on the token of each exchange.",
    subTitle2:
      "If your token is listed on multiple exchanges, please choose multiple packages when paying.",
    BotRentalImg: "/images/mm/bot-rental-3.png",
    MMImg: "/images/mm/market-marking.png",
  },
  Basic: {
    title: "Basic Package Price Sheet",
    sheetImg: "/images/mm/mmbot-basic.png",
  },
  VIP: {
    title: "VIP Package Price Sheet",
    sheetImg: "/images/mm/mmbot-vip.png",
    robotImg: "/images/mm/mm-robot.png",
  },
};
