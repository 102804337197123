import {
  Box,
  Container,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Stack,
} from "@mui/material";
import { IconArrowBigRight, IconMenu2 } from "@tabler/icons-react";
import { ButtonLoadingPrimary } from "components/common/CustomButton";
import Logo from "components/common/Logo";
import useResponsive from "hooks/useResponsive";
import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  DropdownBox,
  HeaderBox,
  MenuLinkBox,
  Navbar,
} from "./components/HeaderStyled";
import { MENU_CONFIG, MENU_EXTERNAL_CONFIG } from "./components/Menu";

export default function Header({ hiddenLogo = false }) {
  const isMobile = useResponsive("down", "sm");
  const [scrollPositionToggle, setScrollPositionToggle] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const pathName = useLocation();
  // console.log(pathName.hash);
  useEffect(() => {
    if (pathName.hash.includes("#")) {
      scroll(pathName.hash);
    }
  }, [pathName]);
  const handleOpen = () => {
    setShowSidebar(true);
  };

  const handleClose = () => {
    setShowSidebar(false);
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > (isMobile ? 35 : 75)) {
      setScrollPositionToggle(true);
    } else {
      setScrollPositionToggle(false);
    }
  };
  const scroll = (id: string) => {
    const section = document.querySelector(`${id}`);
    section?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderBox id='header'>
      <Stack className={scrollPositionToggle ? "sticky-scroll" : "not-scroll"}>
        <Container
          maxWidth={"xl"}
          sx={{
            display: "flex",
            flexDirection: "column",
            zIndex: 1,
          }}>
          <Navbar
            sx={{ justifyContent: hiddenLogo ? "flex-end" : "space-between" }}>
            {!hiddenLogo ? (
              <Logo />
            ) : (
              <Logo
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "20px",
                  zIndex: "10",
                  width: "150px",
                }}
              />
            )}
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                gap: "16px",
              }}>
              <Hidden lgDown>
                <MenuLinkBox>
                  {MENU_CONFIG.map((item, index) => {
                    if (item.url.indexOf("#") <= -1) {
                      return (
                        <Box key={index}>
                          {item.externalLink ? (
                            <NavLink
                              to={item.url}
                              className={"menu-item"}
                              target='_blank'>
                              {item.title}
                            </NavLink>
                          ) : (
                            <DropdownBox>
                              <Link
                                to={item.url}
                                className={
                                  pathName.pathname === item.url
                                    ? "menu-item active"
                                    : "menu-item"
                                }
                                onClick={() => {
                                  scroll("#top");
                                }}>
                                {item.title}
                              </Link>
                              {item?.subMenu && (
                                <Box
                                  className={"dropDown"}
                                  sx={{
                                    position: "absolute",
                                    right: "12px",
                                    top: "24px",
                                    paddingTop: "20px",
                                    zIndex: 50,
                                  }}>
                                  <Stack
                                    gap={1}
                                    sx={{
                                      background: "var(--color-third)",
                                      backdropFilter: "blur(10px)",
                                      borderRadius: "15px",
                                      border: "1px solid var(--color-primary)",
                                      color: "var(--color-text-title)",
                                      padding: "16px 32px",
                                      " a": {
                                        whiteSpace: "nowrap",
                                        color: "var(--color-link)",
                                        textDecoration: "none",
                                      },
                                    }}>
                                    {item.subMenu.map((item, index) => (
                                      <Link
                                        key={index}
                                        to={item.url}
                                        className={
                                          pathName.pathname === item.url
                                            ? "menu-item active"
                                            : "menu-item"
                                        }
                                        onClick={() => {
                                          scroll("#top");
                                        }}>
                                        {item.title}
                                      </Link>
                                    ))}
                                  </Stack>
                                </Box>
                              )}
                            </DropdownBox>
                          )}
                        </Box>
                      );
                    } else
                      return (
                        <NavLink
                          key={index}
                          to={item.url}
                          className={"menu-item"}
                          onClick={() => {
                            scroll(item?.urlId || "");
                          }}>
                          {item.title}
                        </NavLink>
                      );
                  })}
                  <ButtonLoadingPrimary
                    href={MENU_EXTERNAL_CONFIG.SignIn}
                    className='shine'>
                    Log In
                  </ButtonLoadingPrimary>
                </MenuLinkBox>
              </Hidden>

              {/* BUTTON MENU MOBILE */}
              <Hidden lgUp>
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <IconButton
                    sx={{
                      "& svg": {
                        color: "var(--color-primary)",
                      },
                    }}
                    onClick={() => {
                      handleOpen();
                    }}>
                    <IconMenu2 />
                  </IconButton>
                  <Drawer
                    open={showSidebar}
                    anchor='right'
                    onClose={handleClose}
                    sx={{
                      "& .MuiPaper-root": {
                        background: "var(--color-third)",
                        backdropFilter: "blur(10px)",
                      },
                    }}>
                    <Box
                      sx={{
                        width: 320,
                      }}>
                      <Box
                        sx={{
                          marginTop: "1rem",
                          marginLeft: "1rem",
                          display: "flex",
                          justifyContent: "space-between",
                        }}>
                        {/* <Languages /> */}
                        <IconButton
                          aria-label='CLose menu list'
                          onClick={handleClose}
                          sx={{ color: "var(--color-primary)" }}>
                          <IconArrowBigRight />
                        </IconButton>
                      </Box>

                      <List sx={{ color: "white" }}>
                        <Divider
                          sx={{
                            borderColor: "var(--color-text)",
                            opacity: "0.2",
                          }}
                        />
                        <MenuLinkBox>
                          <ButtonLoadingPrimary
                            href={MENU_EXTERNAL_CONFIG.SignIn}
                            fullWidth>
                            Log In
                          </ButtonLoadingPrimary>
                        </MenuLinkBox>
                        <MenuLinkBox>
                          {MENU_CONFIG.map((item, index) =>
                            item.subMenu ? (
                              item.subMenu.map((item, index) => (
                                <NavLink
                                  key={index}
                                  to={item.url}
                                  className={"menu-item"}
                                  onClick={() => {
                                    scroll("#top");
                                    handleClose();
                                  }}>
                                  {item.title}
                                </NavLink>
                              ))
                            ) : (
                              <NavLink
                                key={index}
                                to={item.url}
                                className={"menu-item"}
                                onClick={() => {
                                  scroll("#top");
                                  handleClose();
                                }}>
                                {item.title}
                              </NavLink>
                            ),
                          )}
                        </MenuLinkBox>
                        <Divider
                          sx={{
                            borderColor: "var(--color-text)",
                            opacity: "0.2",
                          }}
                        />
                      </List>
                    </Box>
                  </Drawer>
                </Stack>
              </Hidden>
            </Box>
          </Navbar>
        </Container>
      </Stack>
    </HeaderBox>
  );
}
