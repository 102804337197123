import Page from "components/common/Page";
import HomePage from "pages/home/HomePage";
import NotFound from "pages/NotFound";
import MMServices from "pages/mm-services/MMServices";
import MMBot from "pages/mm-bot/MMBot";

export const UserRoutes = [
  {
    path: "",
    element: (
      <Page title='Market Making & Trading Mastery '>
        <HomePage />
      </Page>
    ),
  },
  {
    path: "pricing-bot-rental-service",
    element: (
      <Page title='Bot-Rental Service'>
        <MMBot />
      </Page>
    ),
  },
  {
    path: "pricing-market-making-service",
    element: (
      <Page title='Market Making Service'>
        <MMBot />
      </Page>
    ),
  },
  {
    path: "*",
    element: (
      <Page title='Not Found'>
        <NotFound />
      </Page>
    ),
  },
];

export const AppRoutes = [
  {
    path: "*",
    element: (
      <Page title='Not Found'>
        <NotFound />
      </Page>
    ),
  },
];
