import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material";

// export const ButtonLoadingNormal = styled(LoadingButton)(({ theme }) => ({
//   background: "transparent",
//   color: "var(--color-text-secondary)",
//   fontWeight: "bold",
//   textTransform: "initial",
//   borderRadius: 15,
//   transition: "0.5s",
//   padding: "12px 24px",
//   fontSize: 16,
//   position: "relative",
//   "&::before": {
//     content: "''",
//     position: "absolute",
//     background: "var(--linear-primary)",
//     inset: "0px",
//     zIndex: 1,
//     borderRadius: 15,
//     padding: "1px",
//     WebkitMask:
//       "linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box,linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px)",
//     WebkitMaskComposite: "xor",
//   },
//   " svg": {
//     color: "var(--color-text-secondary)",
//     marginRight: theme.spacing(1),
//   },
//   "&:hover": {
//     background: "var(--linear-primary)",
//     color: "var(--color-accent)",
//     " svg": {
//       color: "var(--color-accent)",
//     },
//   },
//   " .MuiLoadingButton-loadingIndicator": {
//     color: "var(--color-secondary)",
//   },
//   [theme.breakpoints.down("sm")]: {
//     padding: "4px 20px",
//   },
// }));

export const ButtonLoadingPrimary = styled(LoadingButton)(({ theme }) => ({
  background: "var(--linear-primary)",
  color: "var(--color-white)",
  border: "1px solid var(--color-border)",
  fontWeight: "bold",
  textTransform: "initial",
  borderRadius: 15,
  transition: "0.5s",
  padding: "10px 30px",
  position: "relative",
  fontSize: 16,
  "&:hover": {
    boxShadow: "var(--shadow-primary)",
  },
  "&.Mui-disabled": {
    background: "var(--linear-primary-10)",
    color: "var(--color-primary)",
  },
  " .MuiLoadingButton-loadingIndicator": {
    color: "var(--color-secondary)",
  },
  [theme.breakpoints.down("sm")]: {
    borderRadius: 8,
    padding: "8px 24px",
    height: "fit-content",
    fontSize: 14,
  },
}));

export const ButtonLoadingSecondary = styled(LoadingButton)(({ theme }) => ({
  boxShadow: " 0px 0px 7px 0px rgba(119, 68, 255, 0.70);",
  color: "var(--color-white)",
  borderRadius: 30,
  background: "var(--color-third)",
  backdropFilter: "blur(3.5px)",
  fontWeight: "bold",
  transition: "0.5s",
  padding: "12px 24px",
  border: "1px solid #4467FF",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  "&:hover": {
    color: "var(--color-white)",
    background: "var(--color-third)",
    boxShadow: "var(--shadow-primary)",
    border: "1px solid #4467FF",
  },
  "&.Mui-disabled": {
    color: "#999",
    background: "var( --color-third-30)",
    border: "none",
  },
  " .MuiLoadingButton-loadingIndicator": {
    color: "var(--color-secondary)",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "4px 20px",
    height: "fit-content",
    fontSize: "14px",
  },
}));

// export const ButtonLoadingThird = styled(LoadingButton)(({ theme }) => ({
//   background: "var(--linear-primary-10)",
//   color: "var(--color-secondary)",
//   border: "1px solid var(--color-text-secondary-10)",
//   fontWeight: "bold",
//   textTransform: "initial",
//   borderRadius: 15,
//   transition: "0.5s",
//   padding: "6px 30px",
//   position: "relative",
//   fontSize: 16,
//   "&:hover": {
//     background: "var(--linear-primary)",
//     color: "var(--color-accent)",
//   },
//   "&.Mui-disabled": {
//     background: "var(--linear-primary-10)",
//     color: "var(--color-primary)",
//   },
//   " .MuiLoadingButton-loadingIndicator": {
//     color: "var(--color-secondary)",
//   },
//   [theme.breakpoints.down("sm")]: {
//     padding: "4px 20px",
//     height: "fit-content",
//     fontSize: "14px",
//   },
// }));
