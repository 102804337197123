import { TabList } from "@mui/lab";
import { Box, styled } from "@mui/material";

export const BoxBasicPrice = styled(Box)(({ theme }) => ({
  borderRadius: 30,
  position: "relative",
  background: "#010314",
  div: { zIndex: 1, position: "inherit" },
  "::after": {
    content: '""',
    zIndex: 0,
    background:
      "radial-gradient(300% 100.08% at 50% 18.33%, rgba(0, 0, 0, 0.00) 21.67%, #63E 69.34%, #FFF 100%)",
    height: "50%",
    width: "100%",
    position: "absolute",
    transform: " rotateX(180deg)",
    top: "0",
    left: "0",
    borderRadius: "inherit",
  },
  [theme.breakpoints.down("sm")]: {
    borderRadius: 15,
  },
}));
export const BoxVipPrice = styled(Box)(({ theme }) => ({
  borderRadius: 30,
  overflow: "hidden",
  position: "relative",
  background: "linear-gradient(63deg, #000057 0%, #4C1AB6 100%)",
  div: { zIndex: 1, position: "inherit" },
  "::after": {
    content: '""',
    zIndex: 0,
    background:
      "radial-gradient(300% 100.08% at 50% 18.33%, rgba(0, 0, 0, 0.00) 21.67%, #63E 69.34%, #FFF 100%)",
    height: "50%",
    width: "100%",
    position: "absolute",
    transform: " rotateX(180deg)",
    top: "0",
    left: "0",
    borderRadius: "inherit",
  },
  [theme.breakpoints.down("sm")]: {
    borderRadius: 15,
  },
}));

export const BoxPackagesPrice = styled(Box)(({ theme }) => ({
  borderRadius: 30,
  position: "relative",
  background: "#0E1125",
  div: { zIndex: 1, position: "inherit" },
  "::before": {
    content: '""',
    zIndex: 0,
    background:
      "linear-gradient(180deg, rgba(0, 0, 0, 0.00) -0.14%, rgba(76, 38, 177, 0.74) 100%)",
    height: "10%",
    width: "100%",
    position: "absolute",
    transform: " rotateX(180deg)",
    top: "0",
    left: "0",
    borderRadius: "inherit",
  },
  "::after": {
    content: '""',
    zIndex: 0,
    background:
      "radial-gradient(300% 100.08% at 50% 18.33%, rgba(0, 0, 0, 0.00) 21.67%, #63E 69.34%, #FFF 100%)",
    height: "50%",
    width: "100%",
    position: "absolute",
    // transform: " rotateX(180deg)",
    bottom: "0",
    left: "0",
    borderRadius: "inherit",
  },
  [theme.breakpoints.down("sm")]: {
    borderRadius: 15,
  },
}));

export const SpecialTabList = styled(TabList)(({ theme }) => ({
  transition: "1s",
  background:
    "linear-gradient(360deg, rgba(40, 140, 197, 0.15) 50%, rgba(93, 213, 230, 0.15) 100.31%)",
  borderRadius: "15px",
  position: "relative",
  "&:before": {
    content: '""',
    position: "absolute",
    width: "100%",
    background: "linear-gradient(93deg, #5076FF 3.3%, #8454EB 93.12%)",
    borderRadius: 15,
    padding: "2px",
    inset: "0px",
    WebkitMask:
      "linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px)",
    WebkitMaskComposite: "xor",
    zIndex: 0,
  },
  "& button": {
    padding: "8px 24px",
    color: "#FFF",
    borderRadius: "15px",
    fontWeight: "bold",
    zIndex: "1",
    minWidth: 250,
    fontSize: 18,
    "& span": {
      transition: "0.5s",
      background: "transparent",
      borderRadius: "12px",
    },
    position: "relative",
  },
  "& button.Mui-selected": {
    color: "#fff",
    transition: "1s",
    borderColor: "white",
    boxShadow: "0px 2px 4px 1px rgba(86, 27, 241, 0.50)",
    "& span": {
      transition: "0.5s",
      background: "linear-gradient(93deg, #5076FF 3.3%, #8454EB 93.12%)",
      opacity: "0.9",
      zIndex: "-1",
      borderRadius: "15px",
    },
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      width: "100%",
      background: "linear-gradient(93deg, #5076FF 3.3%, #8454EB 93.12%)",
      borderRadius: 15,
      padding: "2px",
      inset: "0px",
      WebkitMask:
        "linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px) content-box content-box, linear-gradient(rgb(255, 255, 255) 0px, rgb(255, 255, 255) 0px)",
      WebkitMaskComposite: "xor",
      zIndex: 0,
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& button": {
      padding: "0px 12px",
      minWidth: 100,
      fontSize: 12,
    },
  },
}));
