import { Box, styled } from "@mui/material";
export const HeaderBox = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100vw",
  zIndex: "15",
  "&#header": {
    transition: "all 0.4s ease!important",
  },
  "& .not-scroll": {
    transition: "0.5s",
    "& .logo": {
      transition: "0.65s",
      padding: "8px 0",
      width: "260px",
      [theme.breakpoints.down("sm")]: {
        width: "160px",
      },
    },
  },
  "& .sticky-scroll": {
    transition: "0.5s",
    backdropFilter: "blur(10px)",
    background: "rgb(1, 3, 20,0.5)",
    "& .logo": {
      transition: "0.65s",
      padding: "4px 0",
      width: "230px",
      [theme.breakpoints.down("sm")]: {
        width: "140px",
      },
    },
  },
}));

export const Navbar = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px",
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    padding: "4px",
  },
}));

export const MenuLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: 24,
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 16,
    margin: "16px 32px",
  },
  "& a.menu-item": {
    display: "block",
    fontWeight: "600",
    position: "relative",
    fontSize: 16,
    padding: "8px",
    transition:
      "opacity 300ms ease, background-color 300ms ease, color 300ms ease",
    color: "rgb(255,255,255,0.6)",
    textDecoration: "none",
    "&:last-child": {
      marginRight: "0",
    },
    "&.logo": {
      padding: "0",
      margin: "0",
    },
    // "&.active": {
    //   borderBottom: "3px solid var(--color-primary)",
    //   fontWeight: "bold",
    // },
    "&::before": {
      position: "absolute",
      content: '""',
      width: "100%",
      height: "1px",
      background: "var(--linear-primary)",
      top: "90%",
      left: "0",
      pointerEvents: "none",
      transformOrigin: "100% 50%",
      transform: "scale3d(0, 1, 1)",
      transition: "transform 0.3s cubic-bezier(0.7, 0, 0.2, 1)",
    },
    "&::after": {
      position: "absolute",
      content: '""',
      width: "100%",
      height: "1px",
      background: "var(--linear-primary)",
      top: "calc(90% + 4px)",
      left: "0",
      pointerEvents: "none",
      transformOrigin: "0% 50%",
      transform: "scale3d(0, 1, 1)",
      transition: "transform 0.3s cubic-bezier(0.7, 0, 0.2, 1)",
    },

    "&:hover": {
      background: "transparent",
      color: "var(--color-primary)",
      "&::before": {
        transformOrigin: "0% 50%",
        transform: "scale3d(1, 1, 1)",
        transitionTimingFunction: "cubic-bezier(0.4, 1, 0.8, 1)",
      },
      "&::after": {
        transformOrigin: "100% 50%",
        transform: "scale3d(1, 1, 1)",
        transitionTimingFunction: "cubic-bezier(0.4, 1, 0.8, 1)",
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    "& a.menu-item": {
      fontWeight: "600",
      fontSize: 14,
      padding: "0 0 4px",
      "&.active": {
        borderBottom: "1px solid var(--color-primary)",
      },
    },
  },
}));

export const DropdownBox = styled(Box)(({ theme }) => ({
  position: "relative",
  background: "var(--linear-primary-10)",
  padding: 4,
  paddingRight: 32,
  borderRadius: "15px",
  cursor: "pointer",
  transition: "all 0.3s",
  "& .dropDown": {
    display: "none",
  },
  "&::before": {
    content: "url('/images/icons/arrow-down.png')",
    position: "absolute",
    top: "50%",
    right: "15%",
    transform: "translateY(-50%)  rotate(270deg)",
    transition: "all 0.3s",
  },
  "&:hover": {
    "&::before": {
      transform: "translateY(-50%) rotate(360deg)",
      transition: "all 0.3s",
    },
    "& .dropDown": {
      display: "block",
    },
  },
}));
