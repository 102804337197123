import { Box, Container, Grid, Stack } from "@mui/material";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";
import {
  BoxAccent,
  BoxRadialPrimary,
  ImgAccent,
} from "components/common/CustomBox";

export default function About() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={isMobile ? "auto" : "calc(100vh - 150px)"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      py={isMobile ? 2 : 10}>
      <BoxAccent
        sx={{
          width: "800px",
          height: "800px",
          borderRadius: "50%",
          background: "rgba(120, 74, 253, 0.30)",
          filter: "blur(250px)",
          zIndex: 0,
          top: "10%",
          right: "-25%",
        }}
      />
      <BoxAccent
        sx={{
          width: "450px",
          height: "450px",
          borderRadius: "50%",
          background: "#2745EA",
          filter: "blur(250px)",
          zIndex: 0,
          bottom: "-25%",
          left: "-15%",
        }}
      />
      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
          mt: isMobile ? 8 : 0,
        }}>
        <Grid
          container
          my={isMobile ? 2 : 8}
          sx={{
            justifyContent: "space-between",
            flexDirection: isDesktop ? "row" : "column-reverse",
          }}>
          <Grid item xs={12} md={5.5}>
            <BoxRadialPrimary
              data-aos='zoom-in-right'
              data-aos-duration='500'
              data-aos-once='true'
              position={"relative"}
              height={"120%"}
              sx={{
                minHeight: isMobile ? 250 : 350,
                mx: isMobile ? 2 : "auto",
              }}
              mt={isDesktop ? 0 : 5}>
              <ImgAccent
                src='/images/home/about.png'
                alt=''
                sx={{
                  height: "80%",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              />
              <BoxAccent
                sx={{
                  height: "14px",
                  width: "14px",
                  borderRadius: "50%",
                  background: "#fff",
                  top: "24px",
                  left: "30%",
                }}
              />
              <BoxAccent
                sx={{
                  height: "14px",
                  width: "14px",
                  borderRadius: "50%",
                  background: "#fff",
                  top: "50%",
                  right: "-24px",
                }}
              />
              <BoxAccent
                sx={{
                  height: "24px",
                  width: "24px",
                  borderRadius: "50%",
                  background:
                    "linear-gradient(148deg, #FFF 20.83%, rgba(255, 255, 255, 0) 115.28%)",
                  bottom: "5%",
                  left: "-10%",
                }}
              />
            </BoxRadialPrimary>
          </Grid>
          <Grid item xs={12} md={5.5}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              mb={isMobile ? 2 : 4}
              gap={2}
              data-aos='fade-up'
              data-aos-easing='linear'
              data-aos-once='true'>
              <img
                src='/images/home/star-3.png'
                width={isMobile ? 30 : "auto"}
                alt=''
              />
              <TypographyWhite variant={isMobile ? "h4" : "h2"}>
                {ContentHome.About.title}
              </TypographyWhite>
            </Stack>
            <Box
              data-aos='fade-up'
              data-aos-easing='linear'
              data-aos-once='true'>
              <TypographyText
                lineHeight={isMobile ? "1.5" : "2"}
                textAlign={"justify"}>
                {ContentHome.About.description}
              </TypographyText>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
