export const ContentHome = {
  Introduce: {
    title: "Revolutionizing Market Making with Advanced AI",
    description: `Harness the power of Artificial Intelligence to optimize liquidity and trading volume on your exchange. Experience unparalleled market efficiency with SPTrade Pro's AI-driven Market Making solutions.`,
  },
  About: {
    title: "About SPTrade Pro",
    description: `At SPTrade Pro, we specialize in providing tailored Market Making solutions to help cryptocurrency exchanges achieve optimal trading conditions. Our team of experts utilizes industry-leading strategies to ensure a seamless trading experience, fostering a robust and liquid market environment. With a strong focus on transparency and integrity, we strive to build long-term relationships with our clients. SPTrade Pro is at the forefront of liquidity provision, partnering with emerging and established projects to enhance market efficiency across all major trading platforms. Our expertise spans centralized exchanges (CEX), decentralized exchanges (DEX), and liquidity pools, ensuring a seamless trading experience for our clients. With a proven track record of success, we are the go-to market maker for many of the world's leading blockchain projects.`,
  },
  Services: {
    title: "Our Services",
    description: `We offer a comprehensive suite of Market Making services designed to meet the unique needs of each exchange. Our services include volume support, liquidity support, and a cash-out feature to ensure a well-balanced and active trading ecosystem. With our advanced analytics dashboard, clients can monitor real-time performance and make informed decisions. Our comprehensive suite of services extends beyond mere liquidity provision. We offer competitive spreads even in volatile market conditions, ensuring your token remains attractive to traders at all times. Our 24/7/365 liquidity provision guarantees that your project never faces a liquidity crunch. Additionally, our advanced analytics dashboard provides real-time insights into trading activity, empowering you with the data needed to make informed decisions.`,
  },
  Solutions: {
    title: "Tailored Solutions for Blockchain Projects",
    description: `We provide tailored liquidity provision solutions for blockchain projects, ensuring coverage across CEX, DEX, and liquidity pools. Our services come with no integration, monthly, or other hidden fees, providing a transparent and cost-effective solution for your project. Our dedicated DeFi team works closely with projects to offer guidance on all aspects of liquidity, from bridging tokens across chains to optimizing trading strategies.`,
  },
  Supported: {
    title: "Supported Exchanges",
    description: `We collaborate with a variety of major exchanges including Binance, Coinbase Exchange, Kraken, and many more to deliver top-notch services. Our extensive network allows us to provide unparalleled Market Making solutions across different trading platforms. Explore the list of exchanges we support and discover the benefits of partnering with SPTrade Pro.`,
  },
  WhyChoose: {
    title: "Why Partner with SPTrade Pro",
    description: `We are committed to fostering long-term relationships with ambitious projects aiming for listing on top-tier exchanges. Our approach goes beyond trading tokens; we provide holistic support to projects building the future of the blockchain ecosystem. With SPTrade Pro, you are not just getting a service provider; you are gaining a partner dedicated to supporting innovation in the blockchain space.`,
  },
  Pricing: {
    title: "Transparent Pricing",
    description: `Our pricing models are transparent and competitive, ensuring you get the best value for your investment. Explore our pricing options to find a package that suits your Market Making needs. We believe in clear pricing with no hidden fees, providing a straightforward path to enhance your exchange's liquidity and trading volume.`,
  },
  GetInTouch: {
    title: "Get in Touch",
    description: `Interested in creating liquid markets for your project? Reach out to us at contact@sptradepro.com. For media inquiries, contact@sptradepro.com. Join our growing team by applying online or emailing contact@sptradepro.com. Stay connected with us on Twitter, LinkedIn, and Medium for the latest updates.`,
  },
  Updates: {
    title: "Latest Updates",
    description: `Stay updated with the latest trends in the industry and news from SPTrade Pro. Our blog features insightful articles, market analyses, and updates on our services and partnerships.`,
  },
};

export const ContentSocial = {
  Telegram: {
    title: "Find us on Telegram",
    name: "@sptradeprochannel",
    link: "https://t.me/sptradeprochannel",
  },
  Twitter: {
    title: "Follow us on X",
    name: "@SPTradePro",
    link: "https://twitter.com/SPTradePro",
  },
};

export const Exchanges = [
  { id: 1, logo: "binance", name: "Binance", link: "https://www.binance.com/" },
  {
    id: 2,
    logo: "bithumb",
    name: "bithumb",
    link: "https://www.bithumb.com/react/",
  },
  { id: 3, logo: "kucoin", name: "Kucoin", link: "https://www.kucoin.com/" },
  { id: 4, logo: "mexc", name: "MEXC", link: "https://www.mexc.com/" },
  { id: 5, logo: "houbi", name: "Houbi", link: "https://www.huobi.com/" },
  { id: 6, logo: "gate", name: "Gate", link: "https://www.gate.io/" },
  { id: 7, logo: "upbit", name: "UPbit", link: "https://upbit.com/home" },
  {
    id: 8,
    logo: "gopax",
    name: "GOPAX",
    link: "https://www.gopax.co.kr/exchange",
  },
  { id: 9, logo: "bingx", name: "BingX", link: "https://bingx.com/" },
  { id: 10, logo: "okx", name: "OKX", link: "https://www.okx.com/" },
  { id: 11, logo: "bitget", name: "Bitget", link: "https://www.bitget.com/" },
  { id: 12, logo: "phemex", name: "Phemex", link: "https://phemex.com/" },
  { id: 13, logo: "bybit", name: "Bybit", link: "https://www.bybit.com/" },
  {
    id: 14,
    logo: "crypto",
    name: "Crypto.com",
    link: "https://crypto.com/",
  },
  { id: 15, logo: "lbank", name: "Lbank", link: "https://www.lbank.com/" },
  {
    id: 16,
    logo: "bitmart",
    name: "Bitmart",
    link: "https://www.bitmart.com/",
  },
  {
    id: 17,
    logo: "bitforex",
    name: "Bitforex",
    link: "https://www.bitforex.com/",
  },
  { id: 18, logo: "latoken", name: "LATOKEN", link: "https://latoken.com/" },
  { id: 19, logo: "xt", name: "XT.COM", link: "https://www.xt.com/" },
  { id: 20, logo: "btcex", name: "btcex", link: "https://www.btcex.com/" },
  { id: 21, logo: "coinone", name: "Coinone", link: "https://coinone.co.kr/" },
  { id: 22, logo: "coinw", name: "CoinW", link: "https://www.coinw.com/" },
  {
    id: 23,
    logo: "biconomy",
    name: "Biconomy",
    link: "https://www.biconomy.com/",
  },
  {
    id: 24,
    logo: "toobit",
    name: "Toobit",
    link: "https://www.toobit.com/en-US/",
  },
  {
    id: 25,
    logo: "coinstore",
    name: "Coinstore",
    link: "https://www.coinstore.com/",
  },
];
