import { Box, Container, Stack } from "@mui/material";
import { BoxRadialPrimary } from "components/common/CustomBox";
import { DividerBox } from "components/common/CustomDivider";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";

export default function Pricing() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 150px)"}
      py={isMobile ? 2 : 10}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}>
      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
        }}>
        <Stack
          gap={isMobile ? 2 : 3}
          alignItems={"center"}
          px={isDesktop ? 10 : 2}
          mb={2}>
          <TypographyWhite
            variant={isMobile ? "h4" : "h2"}
            data-aos='fade'
            data-aos-easing='linear'
            data-aos-once='true'>
            {ContentHome.Pricing.title}
          </TypographyWhite>
          <DividerBox height={isMobile ? 2 : 4} />
          <TypographyText
            data-aos='fade-up'
            data-aos-easing='linear'
            data-aos-once='true'
            data-aos-delay='200'
            lineHeight={isMobile ? "1.5" : "2"}
            textAlign={"center"}>
            {ContentHome.Pricing.description}
          </TypographyText>
        </Stack>
        <BoxRadialPrimary
          data-aos='zoom-in-up'
          data-aos-easing='linear'
          data-aos-once='true'
          data-aos-delay='300'
          sx={{
            border: "none",
            background:
              "radial-gradient(217.23% 100.08% at 50% 18.33%, rgba(0, 0, 0, 0.00) 30%, #63E 70%, #FFF 100%)",
            py: isDesktop ? 10 : 2,
          }}>
          <Stack alignItems={"center"}>
            <img src={`/images/home/pricing.png`} alt='' />
          </Stack>
        </BoxRadialPrimary>
      </Container>
    </Box>
  );
}
