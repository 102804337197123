import { Box, Container, Grid, Stack } from "@mui/material";
import { BoxAccent, ImgAccent } from "components/common/CustomBox";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome } from "./Content";

export default function Services() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box id='services'>
      <Box
        position={"relative"}
        minHeight={"calc(100vh - 150px)"}
        py={isMobile ? 2 : 10}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        data-aos='zoom-in-left'
        data-aos-easing='linear'
        data-aos-once='true'>
        <BoxAccent
          sx={{
            width: "550px",
            height: "550px",
            borderRadius: "50%",
            background: "#925792",
            filter: "blur(250px)",
            zIndex: 0,
            bottom: "-25%",
            right: "-15%",
          }}
        />
        <Container
          maxWidth='xl'
          sx={{
            zIndex: 1,
            position: "inherit",
          }}>
          <Grid container my={8} sx={{ justifyContent: "space-between" }}>
            <Grid item xs={12} md={5.25}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                mb={isMobile ? 2 : 4}
                gap={2}
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-once='true'
                data-aos-delay='200'>
                <img
                  src='/images/home/star-3.png'
                  width={isMobile ? 30 : "auto"}
                  alt=''
                />
                <TypographyWhite variant={isMobile ? "h4" : "h2"}>
                  {ContentHome.Services.title}
                </TypographyWhite>
              </Stack>
              <Box
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-once='true'
                data-aos-delay='300'>
                <TypographyText
                  lineHeight={isMobile ? "1.5" : "2"}
                  textAlign={"justify"}>
                  {ContentHome.Services.description}
                </TypographyText>
              </Box>
            </Grid>
            {!isDesktop && (
              <Grid item xs={12}>
                <Box mr={-4} mt={4}>
                  <Box
                    component={"img"}
                    src='/images/home/services.png'
                    alt=''
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </Container>
        {isDesktop && (
          <ImgAccent
            src='/images/home/services.png'
            alt=''
            sx={{
              top: "50%",
              right: "0",
              transform: "translateY(-50%)",
              maxWidth: "50%",
            }}
          />
        )}
      </Box>
    </Box>
  );
}
