import { en } from "languages/en";
import { Reducer } from "redux";
import { SettingStoreConstant } from "./settingConstants";

const initialState: TSettingInitialState = {
  library: en,
  bcConfig: null,
};

export const SettingReducer: Reducer<TSettingInitialState, TReduxAction> = (
  state = initialState,
  action,
) => {
  const { type, payload } = action;
  switch (type) {
    case SettingStoreConstant.CHANGE_LANGUAGE:
      return { ...state, library: payload };
    case SettingStoreConstant.SET_BC_CONFIG:
      return { ...state, bcConfig: payload };
    default:
      return state;
  }
};
