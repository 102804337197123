import { Box, styled } from "@mui/material";
import Slider from "react-slick";

export const BoxParnerLogo = styled(Box)(({ theme }) => ({
  transition: "transform 150ms ease-in-out",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const NetworkBox = styled(Box)(({ theme }) => ({
  "& a": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    gap: 8,
    padding: "4px 16px",
    borderRadius: "0.5rem",
  },
}));

export const SliderCustom = styled(Slider)(() => ({
  "& .slick-slide": {
    transition: "all 0.3s ease-in-out",
    padding: "15px 10px!important",
    "&.slick-active": {
      opacity: "1",
    },
    "&.slick-current": {
      opacity: "1",
    },
    "&.slick-center": {
      marginTop: "-3rem",
    },
    "&.slick-prev": {
      height: "3rem",
    },
  },
  "& .slick-dots": {
    bottom: "-16px",
    "& li": {
      width: "unset",
    },
    "& li button": {
      width: "8px",
    },
    "& li button:before": {
      content: '""',
      background: "var(--color-primary)",
      padding: 0,
      opacity: 0.25,
      borderRadius: "8px",
      height: "8px",
      width: "8px",
      transition: "0.5s",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
    },
    "& li.slick-active button:before": {
      borderRadius: "10px",
      width: "32px",
      transition: "0.5s",
    },
    "& li.slick-active button": {
      width: "32px",
    },
  },
}));

export const BoxExchange = styled(Box)(({ theme }) => ({
  borderRadius: 10,
  background:
    "linear-gradient(148deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%)",
  backdropFilter: "blur(7px)",
  padding: 32,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  transition: "all 1s",
  "& .rounded": {
    transition: "all 1s",
    borderRadius: "50%",
    padding: 32,
    background:
      "linear-gradient(148deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%)",
    img: {
      maxWidth: 80,
    },
  },
  ":hover": {
    background:
      "linear-gradient(148deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)",
    "& .rounded": {
      background:
        "linear-gradient(148deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)",
      img: {
        maxWidth: 80,
      },
    },
  },
  [theme.breakpoints.down("md")]: {
    padding: 16,
    "& .rounded": {
      transition: "all 1s",
      borderRadius: "50%",
      padding: 12,
      background:
        "linear-gradient(148deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%)",
      img: {
        maxWidth: 48,
      },
    },
  },
  [theme.breakpoints.down("sm")]: {
    padding: 16,
    "& .rounded": {
      transition: "all 1s",
      borderRadius: "50%",
      padding: 12,
      background:
        "linear-gradient(148deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%)",
      img: {
        maxWidth: 48,
      },
    },
  },
}));

export const TelegramBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  flexDirection: "row",
  gap: 16,
  padding: "10px 20px 10px 10px",
  borderRadius: "16px",
  background: "linear-gradient(180deg, #2AABEE 0%, #229ED9 100%)",
  overflow: "hidden",
  position: "relative",
  minWidth: "340px",
  [theme.breakpoints.down("sm")]: {
    minWidth: "250px",
  },
}));
export const TwitterBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  flexDirection: "row",
  minWidth: "340px",
  gap: 16,
  padding: "10px 20px 10px 10px",
  borderRadius: "16px",
  background: "#FFF",
  overflow: "hidden",
  position: "relative",
  color: "#10182C",
  [theme.breakpoints.down("sm")]: {
    minWidth: "250px",
  },
}));
