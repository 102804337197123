import { Box, styled } from "@mui/material";

export const BoxSpaceBetween = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 16,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 8,
  },
}));

export const ImgAccent = styled("img")(({ theme }) => ({
  position: "absolute",
}));
export const BoxAccent = styled(Box)(({ theme }) => ({
  position: "absolute",
}));

export const BoxRadialPrimary = styled(Box)(({ theme }) => ({
  border: "1px solid #2A2B3A",
  borderRadius: 20,
  background: "var(--radial-primary)",
  padding: 24,
  minHeight: 200,
  [theme.breakpoints.down("sm")]: {
    borderRadius: 15,
  },
}));

export const BoxRadialBorderPrimary = styled(Box)(({ theme }) => ({
  borderRadius: 60,
  background: "var(--radial-primary)",
  padding: 24,
  minHeight: 200,
  borderTop: "2px solid #664DFF",
  [theme.breakpoints.down("sm")]: {
    borderRadius: 30,
  },
}));

export const BoxPartner = styled(Box)(({ theme }) => ({
  padding: 22,
  borderTop: "2px solid #664DFF",
  borderRadius: 10,
  background:
    "linear-gradient(148deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.05) 100%)",
  backdropFilter: "blur(6)",
}));
