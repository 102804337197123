import { Box, Container } from "@mui/material";
import { styled } from "@mui/system";
import { networksSliderSettings } from "./SliderSettings";
import { NetworkBox, SliderCustom } from "./Styled";

const CustomLogo = styled("img")(() => ({
  transition: "transform 150ms ease-in-out",
  padding: 0,
  display: "block",
  maxHeight: "45px",
  "&:hover": {
    transition: "transform 150ms ease-in-out",
    transform: "scale(1.05)",
  },
}));

const networks = [
  {
    label: "logo",
    link: "/",
  },
  {
    label: "logo",
    link: "/",
  },
  {
    label: "logo",
    link: "/",
  },
  {
    label: "logo",
    link: "/",
  },
  {
    label: "logo",
    link: "/",
  },
  {
    label: "logo",
    link: "/",
  },
  {
    label: "logo",
    link: "/",
  },
];

export default function Networks() {
  return (
    <Box
      id='Network'
      sx={{
        background: "var(--linear-primary)",
        position: "relative",
        mt: -3,
        zIndex: 1,
      }}>
      <Container maxWidth='xl'>
        <SliderCustom {...networksSliderSettings}>
          {networks.map((network, index) => (
            <NetworkBox key={index}>
              <a
                href={network.link}
                target='_blank'
                rel='noreferrer'
                key={index}>
                <img src='/images/home/star-1.png' alt='' />
                <CustomLogo
                  src={`./images/networks/${network.label}.png`}
                  alt={network.label}
                />
              </a>
            </NetworkBox>
          ))}
        </SliderCustom>
      </Container>
    </Box>
  );
}
