import { Box, Container, Grid, Link, Stack } from "@mui/material";
import { DividerBox } from "components/common/CustomDivider";
import {
  TypographyText,
  TypographyWhite,
} from "components/common/CustomTypography";
import useResponsive from "hooks/useResponsive";
import { ContentHome, Exchanges } from "./Content";
import { BoxExchange } from "./Styled";
import { BoxAccent } from "components/common/CustomBox";

export default function Supported() {
  const isDesktop = useResponsive("up", "md");
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      id={"supported-exchanges"}
      position={"relative"}
      minHeight={"calc(100vh - 150px)"}
      py={isMobile ? 8 : 20}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}>
      <BoxAccent
        sx={{
          width: "550px",
          height: "1100px",
          borderRadius: "50%",
          background: "rgba(120, 74, 253, 0.25)",
          filter: "blur(300px)",
          zIndex: 0,
          top: "10%",
          right: "-10%",
        }}
      />
      <Container
        maxWidth='xl'
        sx={{
          zIndex: 1,
          position: "inherit",
        }}>
        <Stack
          gap={isMobile ? 2 : 3}
          alignItems={"center"}
          px={isMobile ? 2 : 10}
          mb={4}>
          <TypographyWhite
            variant={isMobile ? "h4" : "h2"}
            data-aos='fade'
            data-aos-easing='linear'
            data-aos-once='true'>
            {ContentHome.Supported.title}
          </TypographyWhite>
          <DividerBox height={isMobile ? 2 : 4} />
          <TypographyText
            lineHeight={isMobile ? "1.5" : "2"}
            textAlign={"center"}
            data-aos='fade-up'
            data-aos-easing='linear'
            data-aos-once='true'
            data-aos-delay='200'>
            {ContentHome.Supported.description}
          </TypographyText>
        </Stack>
        <Box paddingLeft={isMobile ? 0 : 12} paddingRight={isMobile ? 0 : 12}>
          <Grid container spacing={isDesktop ? 4 : 2}>
            {Exchanges.map((item) => (
              <Grid
                item
                xs={4}
                sm={3}
                md={2.4}
                key={item.id}
                data-aos='fade-up'
                data-aos-easing='linear'
                data-aos-once='true'
                data-aos-delay={
                  200 + (item.id % 5 === 0 ? 5 : item.id % 5) * 50
                }>
                <Link href={item.link} target='_blank' rel='noreferrer'>
                  <BoxExchange>
                    <Box className='rounded'>
                      <img src={`/images/exchanges/${item.logo}.png`} alt='' />
                    </Box>
                    <TypographyWhite mt={isDesktop ? 4 : 2}>
                      {item.name}
                    </TypographyWhite>
                  </BoxExchange>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
