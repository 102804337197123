import { styled } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import { Box } from "@mui/material";

const RootStyle = styled("div")({
  color: "#fff",
});
const MainStyle = styled("div")(({ theme }) => ({
  background: "var(--color-third)",
  minHeight: "100vh",
  overflowX: "hidden",
  // paddingTop: theme.spacing(20),
  [theme.breakpoints.down("sm")]: {
    // paddingTop: theme.spacing(12),
  },
}));

export default function MainLayout() {
  return (
    <RootStyle>
      <Box id='top' />
      <Header />
      <MainStyle>
        <Outlet />
      </MainStyle>
      <Footer />
    </RootStyle>
  );
}
