import { Box } from "@mui/material";
import { ImgAccent } from "components/common/CustomBox";
import useResponsive from "hooks/useResponsive";

export default function Planet() {
  const isMobile = useResponsive("down", "sm");

  return (
    <Box
      position={"relative"}
      minHeight={isMobile ? "80vh" : "120vh"}
      pt={isMobile ? 0 : 10}
      overflow={"hidden"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        background:
          "linear-gradient(180deg, rgba(14, 10, 24, 0.00) 0%, #0E0A18 58.74%)",
      }}>
      <ImgAccent
        src='/images/home/stars.png'
        className='rotate-stars'
        sx={{
          width: isMobile ? "150%" : "100%",
          maxWidth: isMobile ? "150%" : "100%",
          top: isMobile ? "10%" : "-40%",
          zIndex: 0,
        }}
      />
      <Box mt={isMobile ? -50 : 0} mb={isMobile ? -70 : "min(-17%,-210px)"}>
        <Box
          position={"relative"}
          sx={{ transform: isMobile ? "scale(0.5)" : "scale(1)" }}>
          <Box className='orbit-6' sx={{ padding: 8 }}>
            <Box className='orbit-5' sx={{ padding: 8 }}>
              <Box className='orbit-4' sx={{ padding: 8 }}>
                <Box className='orbit-3' sx={{ padding: 8 }}>
                  <Box className='orbit-2' sx={{ padding: 8 }}>
                    <Box
                      className='orbit-1'
                      sx={{ width: "400px", height: "400px" }}>
                      <Box className='orbit-round' />
                    </Box>
                    <Box className='orbit-round' />
                  </Box>
                  <Box className='orbit-round' />
                </Box>
                <Box className='orbit-round' />
              </Box>
              <Box className='orbit-round' />
            </Box>
            <Box className='orbit-round' />
          </Box>
          <ImgAccent
            alt=''
            src='/images/home/robot-2.png'
            sx={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "350px",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
